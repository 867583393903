import {createApp, defineAsyncComponent, h} from 'vue';
import Pusher, {Channel} from 'pusher-js';
import {Modal} from "bootstrap";
import i18n from "../lang"
import RequestPermissionModal from "@/components/helpers/requestPermissionModal.vue";

interface pusherData{
    method:string,
    data:any
}
export default function pusherPlugin(pusherKey:string,pusherChannel:string) {
   // Pusher.logToConsole = true;
    const pusher:Pusher = new Pusher(pusherKey, {
            cluster: 'eu',
        });
        const channel:Channel = pusher.subscribe(pusherChannel);
        channel.bind('event', (data:pusherData) =>handleDataMethod(data));
        pusher.connect();
}
 const handleDataMethod = async (data: pusherData) => {
    if (data.method === 'showModal') {
        showModal(data.data);
        return;
    }
    if (data.method === 'sendNotification') {
        await sendNotification(data.data);
        return;
    }
};

const showModal = (text:string):void => {
    const modal = document.createElement('div');
    modal.classList.add('modal', 'fade');
    modal.innerHTML = `
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Event Received</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>${text}</p>
            </div>
          </div>
        </div>
      `;

    document.body.appendChild(modal);

    const bootstrapModal = new Modal(modal);
    bootstrapModal.show();

    modal.addEventListener('hidden.bs.modal', () => {
        bootstrapModal.dispose();
        modal.remove();
    });
};

let subApp:any=null
const sendNotification = async (text: string):Promise<void> => {
    if (subApp !== null) {
        subApp.unmount()
    }
    subApp = createApp({
        render() {
            return h(RequestPermissionModal, {
                text: text,
            });
        }
    });
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        subApp.use(i18n)
        subApp.mount(containerElement);

};


