{
  "addMoreRooms": "Add more rooms",
  "addMoreRoomsOrBook": "Explore Additional Rooms or Proceed with Booking",
  "addToCart": "Add to cart",
  "address": "Address",
  "adults": "Adults",
  "agreeWithTerms": "I agree with Terms and Conditions",
  "alreadyHaveProfile": "Already have a profile",
  "apartment": "Apartment",
  "applyFilter": "Apply Filter",
  "availableRooms": "Available Rooms",
  "average": "Average",
  "bookingFilterInfo": "Please select your desired dates to continue.",
  "buy": "Buy",
  "callUs": "Call us",
  "changeRooms": "Change the rooms",
  "checkIn": "Check in",
  "checkOut": "Check out",
  "checkout": "Checkout",
  "city": "City",
  "close": "Close",
  "companyEmail": "Company Email",
  "companyName": "Company name",
  "confirm": "Confirm",
  "contactMailSent": "Your message has been sent successfully. We will contact you at the earliest opportunity",
  "count": "Count",
  "country": "Country",
  "dates": "Dates",
  "delivery": "Delivery",
  "deliveryData": "Delivery data",
  "description": "Description",
  "eik": "EIK",
  "email": "Email",
  "enableNotifications": "Enable notifications",
  "enableNotificationsInfo": "Enable notifications to stay updated with the latest news, events, and exclusive offers.",
  "enableNotificationsInfoOff": "We're sorry, but notifications are currently disabled. To ensure you don't miss out on our exciting offers, please grant permission to receive notifications.",
  "entrance": "Entrance",
  "errors": "Errors",
  "fillYourReservationInfo": "Reservation Information",
  "fillYourReservationInfoSub": "Please Provide Your Reservation Details",
  "filter": "Filter",
  "finishOrder": "Finish Order",
  "firstName": "First name",
  "floor": "Floor",
  "for": "for",
  "forPeriod": "Selected dates",
  "forgotYourPassword": "Forgot password?",
  "from": "From",
  "from_date": "From date",
  "gdprText": "By submitting this message to us, you are providing us with your personal data, which we will process and store in accordance with the General Data Protection Regulation (GDPR) of the European Union and our Data Privacy Policy, to which you agree",
  "guests": "Guests",
  "inStock": "In Stock",
  "invoiceData": "Invoice data",
  "keepYourReservationNumbers": "Please keep your reservation numbers!",
  "kid": "Kid",
  "kidYears": "Kid years",
  "kids": "Kids",
  "lastName": "Last name",
  "locale": "en-US",
  "login": "Login",
  "logout": "Logout",
  "message": "Message",
  "mol": "Responsible Official",
  "moreFrom": "More from",
  "night": "night",
  "nights": "nights",
  "noMoreRoom": "There is no enough free rooms!",
  "noRoomSelected": "No rooms selected",
  "note": "Note",
  "numberOfGuests": "Adults number",
  "numberOfKids": "Kids number",
  "order": "Order",
  "orderFor": "Order for",
  "orderNow": "Order Now",
  "outOfStock": "Out of Stock",
  "password": "Password",
  "paymentMethod": "Payment method",
  "perNight": "Per Night",
  "phone": "Phone",
  "pleaseSelectRoomsFirst": "Please select at least one room to proceed with the reservation.",
  "postCode": "Post code",
  "price": "Price",
  "priceFrom": "Price from",
  "product": "Product",
  "products": "Products:",
  "quarter": "Quarter",
  "readyProfile": "Already have profile?",
  "recover": "Recover",
  "reference": "Reference",
  "register": "Register",
  "repeatPassword": "Repeat password",
  "request": "Request",
  "requestPasswordChange": "Request password",
  "requireInvoice": "Require invoice",
  "reservation": "Reservation",
  "reservationDone": "Reservation was successful",
  "reservationSuccess": "Reservation successful!",
  "reserve": "Reserve",
  "returnPolicy": "Return policy",
  "room": "Room",
  "roomSelected": "Room selected successfully!",
  "rooms": "Rooms",
  "saveAReservationHour": "Save a reservation hour",
  "search": "Search",
  "searchResult": "Search Result",
  "select": "Select",
  "selectAdultsAndKids": "Choose the number of adults and kids",
  "selectCity": "Select city",
  "selectCountry": "Select Country",
  "selectGuests": "Select guests",
  "selectOffice": "Select office",
  "selectRoom": "Select",
  "selectRooms": "Select rooms",
  "selectVariation": "Offers for this room",
  "selectedPeriodIsInvalid": "Invalid date selected",
  "sendMessage": "Send message",
  "serviceRequested": "Your reservation was successful",
  "street": "Street",
  "streetNumber": "Street number",
  "subject": "Subject",
  "sure": "Sure",
  "temporaryNotAvailable": "Temporary not available",
  "toAddress": "To address",
  "toOffice": "To office",
  "to_date": "To date",
  "total": "Total",
  "totalSum": "Total Sum",
  "variations": "Variations",
  "vat": "Bulstat",
  "view": "View",
  "viewMore": "View more",
  "weOfferYou": "We offer",
  "yearsOld": "Years old",
  "yourCart": "Your cart",
  "yourCartIsEmpty": "Your cart is empty",
  "yourData": "Your data",
  "yourEmail": "Your email",
  "yourName": "Your name",
  "yourOrder": "Your order",
  "yourPhone": "Your phone",
  "yourProducts": "Your products",
  "adultsCount": "Number of Adults (Ages 15+)",
  "kidsCount": "Number of Kids (Ages 3-14)",
  "readyToPay": "Към плащане",
  "bgn": "лв.",
  "calculatingPrice": "Calculating price",
  "selectAdultsAndKidsInfo": "Please select the number of adults and kids who will be attending:",
  "kids3yearsAreFree": "Kids up to 3 years old are free.",
  "reservationNumber": "Reservation number",
  "totalPrice": "Total price",
  "maxPeriodOfDays": "Selected period can be max 14 days",
  "parking": "Parking",
  "luggage": "Luggage",
  "bar": "Bar",
  "breakfast": "Breakfast",
  "pay": "Pay",
  "cancel": "Cancel",
  "completePurchase": "Complete Your Purchase"
}
