<script setup lang="ts">
import {LocalNotifications, PermissionStatus} from "@capacitor/local-notifications";
import Api from "@/services/Api";
import {Modal} from "bootstrap";
import {onMounted} from "vue";

onMounted( async () => {
    await requestNotification(props.text as string);
});
const props = defineProps({
    text:  {
        type: String,
        required: true
    }
})
const requestNotification = async (text:string):Promise<void> => {
    let status:PermissionStatus = await LocalNotifications.checkPermissions();

    if (status.display == "prompt") {
        Modal.getOrCreateInstance(document.getElementById("needPermission")).show();
    }
    if(status.display == "denied") {
        Modal.getOrCreateInstance(document.getElementById("requestPermission")).show();
    }
    if (status.display === "granted") {
        await LocalNotifications.schedule({
            notifications: [
                {
                    title: "Header",
                    body: text,
                    id: Math.floor(Math.random() * 10),
                    actionTypeId: "",
                    extra: null,
                },
            ],
        });
    }
};
const requestPermission =async () => {
    Modal.getOrCreateInstance(document.getElementById("needPermission")).hide()
    await LocalNotifications.requestPermissions();
}
</script>
<template>
    <div class="modal" tabindex="-1" id="needPermission">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('enableNotifications') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" :aria-label="$t('close')"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t('enableNotificationsInfo') }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="requestPermission()">{{ $t('sure') }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" tabindex="-1" id="requestPermission">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('enableNotifications') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" :aria-label="$t('close')"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t('enableNotificationsInfoOff') }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>




