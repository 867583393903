<script setup lang="ts">
import {defineAsyncComponent, onBeforeMount, onMounted, Ref, ref} from "vue";
import Api from "@/services/Api";
import SystemMessages from "@/components/systemMessages.vue";
import {errorMessages, resultMessages, sectionArea} from "@/router";
import {Capacitor} from "@capacitor/core";
import LoadingComponent from "@/components/LoadingComponent.vue";

const MenuComponent = defineAsyncComponent(() => import('@/components/menuComponent.vue'));
const SectionsComponent = defineAsyncComponent(() => import("@/components/sectionsComponent.vue"));

const loaded=ref(false);
const modulesString = import.meta.env.VITE_MODULES || '';
const m: Ref<string[]> = ref(modulesString ? modulesString.split(',').map((item: string) => item.trim()) : []);

onBeforeMount(async () => {
    Api.init();
    await Api.settings.load();
    if (m.value.length===0 || m.value.includes('MENU')) {
        await Api.menu.load();
    }
    if (m.value.length===0 || m.value.includes('SECTIONS')) {
        await Api.sections.load();
    }
    loaded.value=true;
});
onMounted(() => {

    if (m.value.length===0 || m.value.includes('INFOS')) {
        Api.infos.load();
    }
    if (m.value.length===0 || m.value.includes('PRODUCTS')) {
        Api.cart.load();
        Api.options.load();
        Api.products.loadCategories();
        Api.products.load();
    }
    if (m.value.length===0 || m.value.includes('NEWS')) {
        Api.news.loadCategories();
        Api.news.load();
    }
    if (m.value.length===0 || m.value.includes('ROOMS')) {
        Api.rooms.load();
    }
});

</script>
<template>
    <loading-component v-if="!loaded"></loading-component>
        <div :style="!loaded ? 'display:none':''">
        <div v-if="Capacitor.getPlatform() === 'ios'" class="bg-white pt-5 pb-1"></div>
        <menu-component v-if="Api.menu.all.length"/>
        <sections-component v-if="sectionArea.page"></sections-component>
        <div class="container-fluid px-md-3 px-0 mb-5 siteLoaded">
            <system-messages :errors="errorMessages" :result="resultMessages" ></system-messages>
            <router-view/>
        </div>
    </div>
</template>
<style scoped>
.siteLoaded{
    opacity: 0;
    display: none;
}
</style>
