import {useHead} from "@vueuse/head";
import Api, {saveUserLang} from "@/services/Api";
import i18n from "@/lang";
import {apiBasicInterface} from "@/services/Api/apiBasicResponse";
import {ref} from "vue";
import {createToaster} from "@meforma/vue-toaster";
import {Offcanvas} from "bootstrap";
import Router from "@/router";
const { t } = i18n.global;


export {};

export const needCalendar=ref(false);

export const share=async (title: string, text:string = "", url: string):Promise<void> => {
    const shareData: object = {
        title: title,
        text: text,
        url: url,
    }
    if(navigator['share'] !== undefined) {
        await navigator['share'](shareData);
    }
}

declare global {
    interface Window {
        share:typeof share
    }
}

window.share=share;
const padTo2Digits=(num:number)=> {
    return num.toString().padStart(2, '0');
}
export const mysqlDate = (date:Date)=> {
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('-');
}
export const gridTemplate=(template:string ='grid4') =>{
    if(template==='grid4'){
        return 'row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4'
    }
    if(template==='grid3'){
        return 'row-cols-1 row-cols-sm-2  row-cols-lg-3'
    }
}

export const trans=(key:string)=>t(key);

export const setMetaTags=(info:apiBasicInterface)=>{
    const settings=Api.settings.all;
    let website:string=settings?.site_title;
    let title:string=info?.name ?? settings?.site_title;
    let icon:string=info?.icon ?? settings?.site_icon;
    let short_description:string=info?.short_description ?? settings?.site_description;
    useHead({
        title: title,
        link:[
            {
                key: 'apple-touch-icon',
                rel:`apple-touch-icon`,
                type:`image/webp`,
                href:icon,
            },
            {
                key: 'icon',
                rel:`icon`,
                type:`image/webp`,
                href:icon,
            },
            {
                key: 'shortcut icon',
                rel:`shortcut icon`,
                type:`image/webp`,
                href:icon,
            },
        ],
        meta: [
            {
                name: `description`,
                content: short_description,
            },
            {
                name: `application-name`,
                content: website,
            },
            {
                name: `apple-mobile-web-app-title`,
                content: website,
            },
            {
                name: `author`,
                content: website,
            },
        ],
    })
}
export const showSuccess=async (info: string) => {
    const toaster = createToaster({ /* options */ });
    toaster.show(
        info,
        {
            type:'primary',
            position:"top-right",
            duration:2000
        }
    )
}
export const showCart = () => {
    Router.push('/booking').then(()=>{
        Offcanvas.getOrCreateInstance(document.getElementById('room_cart') as HTMLElement).toggle();
    });

}

export const changeLanguage=(language:string):void=>{
    saveUserLang(language);
    window.location.reload();
}

export const getCurrencyText=(iso:string)=>{
    return t(iso);
}